import type FileAttachmentElement from '@github/file-attachment-element'
import {Attachment} from '@github/file-attachment-element'
import type Batch from '../upload/batch'
import {fetchPoll} from '@github-ui/fetch-utils'
import {observe} from '@github/selector-observer'
import {on} from 'delegated-events'
import {SOFT_NAV_STATE} from '@github-ui/soft-nav/states'
import {softNavigate} from '@github-ui/soft-navigate'
import {remoteForm} from '@github/remote-form'

// Delay file uploads while waiting for a manifest to save.
let savingManifest: Promise<Response> | null = null

// Track saved manifests for delayed uploads.
const manifests = new WeakMap()

function showProgress(target: Element, batch: Batch) {
  const container = target.closest<HTMLElement>('.js-upload-manifest-file-container')!
  const progress = container.querySelector<HTMLElement>('.js-upload-progress')!
  progress.hidden = false
  target.classList.add('is-progress-bar')

  const text = progress.querySelector<HTMLElement>('.js-upload-meter-text')!

  const start = text.querySelector<HTMLElement>('.js-upload-meter-range-start')!
  start.textContent = String(batch.uploaded() + 1)

  const end = text.querySelector<HTMLElement>('.js-upload-meter-range-end')!
  end.textContent = String(batch.size)
}

function hideProgress(target: Element) {
  target.classList.remove('is-progress-bar')

  const container = target.closest<HTMLElement>('.js-upload-manifest-file-container')!
  const progress = container.querySelector<HTMLElement>('.js-upload-progress')!
  progress.hidden = true

  const text = container.querySelector<HTMLElement>('.js-upload-meter-text .js-upload-meter-filename')!
  text.textContent = ''
}

on('file-attachment-accept', '.js-upload-manifest-file', function (event) {
  const {attachments} = event.detail
  const max = parseInt(event.currentTarget.getAttribute('data-directory-upload-max-files') || '', 10)
  if (attachments.length > max) {
    event.preventDefault()
    event.currentTarget.classList.add('is-too-many')
  }
})

on('document:drop', '.js-upload-manifest-tree-view', async function (event) {
  const {transfer} = event.detail
  const target = event.currentTarget
  const files = await Attachment.traverse(transfer, true)
  const url = target.getAttribute('data-drop-url')!

  // Start file upload after turbo visit / pjax to uploads page.
  document.addEventListener(
    SOFT_NAV_STATE.SUCCESS,
    () => {
      document.querySelector<FileAttachmentElement>('.js-upload-manifest-file')!.attach(files)
    },
    {once: true},
  )

  softNavigate(url)
})

on('upload:setup', '.js-upload-manifest-file', async function (event) {
  const {batch, form: policyForm, preprocess} = event.detail
  const container = event.currentTarget

  showProgress(container, batch)

  function addInfo() {
    policyForm.append('upload_manifest_id', manifests.get(container))
  }

  // Manifest has already been saved, so uploader can do its thing.
  if (manifests.get(container)) {
    addInfo()
    return
  }

  // Queue ready function while manifest saves.
  if (savingManifest) {
    // eslint-disable-next-line github/no-then
    preprocess.push(savingManifest.then(addInfo))
    return
  }

  // Save manifest then trigger file uploads.
  const parent = container.closest<HTMLElement>('.js-upload-manifest-file-container')!
  const form = parent.querySelector('.js-upload-manifest-form') as HTMLFormElement
  savingManifest = fetch(form.action, {
    method: form.method,
    body: new FormData(form),
    headers: {Accept: 'application/json'},
  })

  const [first, resolve] = makeDeferred()
  // eslint-disable-next-line github/no-then
  preprocess.push(first.then(addInfo))

  const response = await savingManifest
  if (!response.ok) return
  const result = (await response.json()) as {upload_manifest: {id: string}}

  const commit = document.querySelector<HTMLFormElement>('.js-manifest-commit-form')!
  const manifestIdField = commit.elements.namedItem('manifest_id') as HTMLInputElement
  manifestIdField.value = result.upload_manifest.id

  // Mark manifest as saved so file uploads can begin.
  manifests.set(container, result.upload_manifest.id)
  savingManifest = null

  resolve()
})

function makeDeferred(): [Promise<void>, () => void] {
  let resolve: () => void
  const promise = new Promise<void>(_resolve => {
    resolve = _resolve
  })
  return [promise, resolve!]
}

on('upload:start', '.js-upload-manifest-file', function (event) {
  const {attachment, batch} = event.detail
  const container = event.currentTarget.closest<HTMLElement>('.js-upload-manifest-file-container')!
  const progress = container.querySelector<HTMLElement>('.js-upload-progress')!
  const text = progress.querySelector<HTMLElement>('.js-upload-meter-text')!

  const start = text.querySelector<HTMLElement>('.js-upload-meter-range-start')!
  start.textContent = batch.uploaded() + 1

  const name = text.querySelector<HTMLElement>('.js-upload-meter-filename')!
  name.textContent = attachment.fullPath
})

on('upload:complete', '.js-upload-manifest-file', function (event) {
  const {attachment, batch} = event.detail

  // Add uploaded file to list.
  const template = document.querySelector<HTMLElement>('.js-manifest-commit-file-template')!
  const row = template.querySelector<HTMLElement>('.js-manifest-file-entry')!.cloneNode(true) as HTMLElement
  const name = row.querySelector<HTMLElement>('.js-filename')!
  name.textContent = attachment.fullPath

  const removeButton = row.querySelector<HTMLButtonElement>('[aria-label="Remove this file"]')

  if (removeButton) {
    // eslint-disable-next-line i18n-text/no-en
    removeButton.ariaLabel = `Remove ${attachment.fullPath}`
  }

  const fileID = attachment.id
  const form = row.querySelector<HTMLFormElement>('.js-remove-manifest-file-form')!
  const input = form.elements.namedItem('file_id') as HTMLInputElement
  input.value = fileID

  const list = document.querySelector<HTMLElement>('.js-manifest-file-list')!
  list.hidden = false
  event.currentTarget.classList.add('is-file-list')

  const root = list.querySelector<HTMLElement>('.js-manifest-file-list-root')!
  root.appendChild(row)
  if (batch.isFinished()) {
    hideProgress(event.currentTarget)
  }
})

on('upload:progress', '.js-upload-manifest-file', function (event) {
  const {batch} = event.detail
  const container = event.currentTarget.closest<HTMLElement>('.js-upload-manifest-file-container')!
  const meter = container.querySelector<HTMLElement>('.js-upload-meter')!
  meter.style.width = `${batch.percent()}%`
})

function onerror(event: Event) {
  hideProgress(event.currentTarget as Element)
}

on('upload:error', '.js-upload-manifest-file', onerror)
on('upload:invalid', '.js-upload-manifest-file', onerror)

remoteForm('.js-remove-manifest-file-form', async function (form, wants) {
  await wants.html()

  const root = form.closest<HTMLElement>('.js-manifest-file-list-root')!
  const entry = form.closest<HTMLElement>('.js-manifest-file-entry')!
  entry.remove()

  if (!root.hasChildNodes()) {
    const list = root.closest<HTMLElement>('.js-manifest-file-list')!
    list.hidden = true

    const container = document.querySelector<HTMLElement>('.js-upload-manifest-file')!
    container.classList.remove('is-file-list')
  }
})

async function manifestReadyCheck(el: Element) {
  const url = el.getAttribute('data-redirect-url')!
  try {
    const response = await fetchPoll(el.getAttribute('data-poll-url')!, undefined, undefined, [200, 500], [202, 404])

    // Process 500 errors with error message.
    if (response.status === 500 && response.body) {
      let decodedResponse = ''
      let errorMessage = ''
      const utf8Decoder = new TextDecoder('utf-8')
      const reader = response.body.getReader()
      for (;;) {
        const {value, done} = await reader.read()
        if (done) break

        decodedResponse += utf8Decoder.decode(value, {stream: true})
      }

      const responseAsJson = JSON.parse(decodedResponse)
      const jobInfo = responseAsJson.job
      if (jobInfo) {
        errorMessage = jobInfo.error_message
        const failedRuns = jobInfo.failed_runs

        if (!failedRuns || failedRuns.length === 0) {
          throwManifestFileUploadError(errorMessage)
        }

        const ruleRun = failedRuns[0].rule_run!
        // Check for secret scanning rule violation
        if (ruleRun.rule_type === 'secret_scanning') {
          // Get secrets for the first rule violation
          const candidate = ruleRun.violations.items[0].candidate
          const detectedSecrets = ruleRun.evaluation_metadata.scan_results[candidate].secrets

          if (!detectedSecrets.length) {
            // Nothing to bypass, throw generic rule violation error
            throwManifestFileUploadError(errorMessage)
          }

          const csrfInput = document.querySelector<HTMLInputElement>('.js-push-protection-bypass-csrf')!

          const bypassResponse = await fetch(el.getAttribute('data-secret-bypass-url')!, {
            method: 'POST',
            body: JSON.stringify({file: candidate, secrets: detectedSecrets, ruleRunId: ruleRun.id}),
            headers: {
              Accept: 'application/json',
              'Scoped-CSRF-Token': csrfInput.value,
            },
          })

          if (bypassResponse.ok) {
            const html = await bypassResponse.text()
            document.querySelector<HTMLElement>('.js-manifest-ready-check-failed')!.innerHTML = html

            // Display secret scanning push protection bypass dialog
            const secretBypassDialog = document.getElementById(
              'file-upload-detected-secret-dialog-id',
            ) as HTMLDialogElement
            secretBypassDialog?.show()
          }
        }
      }

      throwManifestFileUploadError(errorMessage)
    } else {
      window.location.href = url
    }
  } catch (e) {
    document.querySelector<HTMLElement>('.js-manifest-ready-check')!.hidden = true
    const failureMessageElement = document.querySelector<HTMLElement>('.js-manifest-ready-check-failed')
    failureMessageElement!.hidden = false

    // Append additional context to the default error message.
    if (e instanceof Error && e.message) {
      const descriptionElement = failureMessageElement!.children[1]
      if (!descriptionElement) {
        return
      }
      descriptionElement.textContent = descriptionElement.textContent!.concat(' ', e.message, '.')
    }
  }
}

function throwManifestFileUploadError(errorMessage: string) {
  if (errorMessage) {
    throw new Error(errorMessage)
  }

  throw new Error()
}

// Navigate to pull request page when manifest commit is ready.
observe('.js-manifest-ready-check', {
  initialize(el) {
    manifestReadyCheck(el)
  },
})

// Navigate back to upload page when detected secrets bypass dialog is closed. Close button behavior
// on Primer::Alpha::Dialog is not directly modifiable via Rails, so we have to add custom JS
observe('.js-file-upload-detected-secret-dialog', {
  add() {
    const dialogCloseButton = document.querySelector('[data-close-dialog-id="file-upload-detected-secret-dialog-id"]')
    const manifestReadyCheckElement = document.querySelector('.js-manifest-ready-check')

    if (!manifestReadyCheckElement) {
      return
    }

    if (dialogCloseButton instanceof HTMLElement) {
      dialogCloseButton.onclick = async (e: MouseEvent) => {
        e.preventDefault()
        const indexPageUrl = manifestReadyCheckElement.getAttribute('data-index-url')

        if (!indexPageUrl) {
          return
        }

        window.location.href = encodeURI(indexPageUrl)
      }
    }
  },
})
